
import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'


import { GlobalProvider } from "./src/context/GlobalContext"

export const wrapRootElement = ({ element }) => (
    <GlobalProvider>{element}</GlobalProvider>

    
  )
  