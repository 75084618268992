import React,{createContext,useState,useRef} from "react"


 const GlobalContext = createContext({})


export const GlobalProvider = ({children})=>{
    const [isAvailable,setIsAvailable] = useState(false)
    const [isDrag,setIsDrag] = useState(false)
    const [closeClass,setcloseClass] = useState(false)
    const [headerVisible,setHeaderVisible] = useState(false)
    
    const scrollRef = useRef(null)
  
 
    return(
        <GlobalContext.Provider value={{isAvailable,setIsAvailable,isDrag,setIsDrag,closeClass,setcloseClass,scrollRef,headerVisible,setHeaderVisible}}>
            {children}
        </GlobalContext.Provider>
    )
}


export default GlobalContext
